import Vue from 'vue'
import App from './App.vue'
import VueSweetalert2 from 'vue-sweetalert2';
import VueClipboard from 'vue-clipboard2'
import './assets/style.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import VTooltip from 'v-tooltip'

Vue.config.productionTip = false


Vue.use(VueSweetalert2);
Vue.use(VueClipboard)
Vue.use(VTooltip)

new Vue({
  render: h => h(App),
}).$mount('#app')
