<template>
  <div id="app">
    <div class="w-full max-w-screen-xl mx-auto px-6">
      <div class="flex justify-center p-4 px-3 py-10">
        <div class="w-full max-w-md">
          <div class="bg-white shadow-md rounded-lg px-3 py-2 mb-4">
            <div class="block text-gray-700 text-lg font-semibold py-2 px-2">
              XML Eşleştirme
              <span class="block text-sm font-normal">
              <small>Kullanılabilir verileri: {{ tagOptions.join(', ') }}</small>
            </span>
            </div>
            <div class="py-3 text-sm max-h-[300px] overflow-y-auto">
              <div
                  v-for="(detail,key) in ideaOptions"
                  :key="key"
                  class="flex items-center justify-between cursor-pointer text-gray-700 hover:text-blue-400 hover:bg-blue-100 rounded-md px-2 py-2 my-2"
              >
                <div class="flex">
                  <span class="bg-gray-400 h-2 w-2 m-2 rounded-full" v-if="detail.tags.length === 0"></span>
                  <span class="bg-green-400 h-2 w-2 m-2 rounded-full" v-else></span>
                  <div class="flex-grow font-medium px-2" v-tooltip="detail.description">{{ key }}<span class="text-red-500" v-if="detail.required"> *</span></div>
                </div>
                <div class="text-sm font-normal text-gray-500 tracking-wide">
                  <VueTagsInput :autocomplete-items="filteredItems(key)" :autocomplete-min-length="0" v-model="detail.val" @tags-changed="newTags => detail.tags = newTags" :tags="detail.tags" :max-tags="1" :add-only-from-autocomplete="true" :autocomplete-always-open="false" placeholder="Seçim Yapınız"/>
                </div>
              </div>
            </div>
            <div class="block bg-gray-200 text-sm text-right py-2 px-3 -mx-3 -mb-2 rounded-b-lg">
              <button class="hover:text-gray-600 text-gray-500 font-bold py-2 px-4" @click="reset()">
                Sıfırla
              </button>
              <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="copyCode">
                Kodu Al
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';
import {createTags} from "@johmun/vue-tags-input/vue-tags-input/create-tags";
import _ from 'lodash';

export default {
  name: 'App',
  components: {
    VueTagsInput
  },
  data() {
    return {
      tag: '',
      ideaOptions: {
        stockCode: {val: '', tags: [], required: false, description: 'Ürün stok kodu.'},
        label: {val: '', tags: [], required: false, description: 'Ürün adı.'},
        status: {val: '', tags: [], required: false, description: 'Ürün durumu. (Aktif-Pasif)'},
        brand: {val: '', tags: [], required: false, description: 'Ürün markası.'},
        mainCategory: {val: '', tags: [], required: false, description: '1. Seviye kategori.'},
        category: {val: '', tags: [], required: false, description: '2. Seviye kategori.'},
        subCategory: {val: '', tags: [], required: false, description: '3. Seviye kategori.'},
        marketPrice: {val: '', tags: [], required: false, description: 'Ürün için piyasa fiyatı.'},
        buyingPrice: {val: '', tags: [], required: false, description: 'Ürün için alış fiyatı.'},
        price1: {val: '', tags: [], required: true, description: 'Ürün satış fiyatı.'},
        tax: {val: '', tags: [], required: false, description: 'KDV oranı. (Varsayılan: 18%)'},
        currencyAbbr: {val: '', tags: [], required: false, description: 'Para birimi.'},
        stockAmount: {val: '', tags: [], required: false, description: 'Stok adedi.'},
        stockType: {val: '', tags: [], required: false, description: 'Stok tipidir. (Varsayılan: Adet)'},
        warranty: {val: '', tags: [], required: false, description: 'Garanti süresi'},
        dm3: {val: '', tags: [], required: false, description: 'Ürün desisi'},
        details: {val: '', tags: [], required: false, description: 'Ürün detayı (açıklaması)'}
      },
      tagOptions: [
        "UrunId",
        "UrunKodu",
        "UreticiKodu",
        "UrunAdi",
        "Uretici",
        "OzelKod1",
        "OzelKod2",
        "OzelKod3",
        "KDV",
        "Birim",
        "Indirim1",
        "Indirim2",
        "Indirim3",
        "Indirim4",
        "Indirim5",
        "Fiyat",
        "IndirimliFiyat",
        "KdvliFiyat",
        "ParaBirimiAdi",
        "GelisTarihi",
        "UrunDurumu",
        "Miktar"
      ],
      autocompleteItems: []
    }
  },
  mounted() {
    this.autocompleteItems = createTags(this.tagOptions)
  },
  methods: {
    reset() {
      this.ideaOptions = _.mapValues(this.ideaOptions, (val) => {
        val.tags = []
        return val
      })
    },
    copyCode() {
      let code = _.map(this.ideaOptions, (val, key) => '"' + key + '" => "' + (val.tags[0]?.text || '') + '"').join(',')
      this.$copyText(code)
      this.$swal(
          'Başarılı',
          'Eşleştirme kodunu başarıyla kopyaladınız.',
          'success'
      )
    },
    filteredItems(key) {
      return this.autocompleteItems.filter(i => {
        return i.text.toLowerCase().indexOf(this.ideaOptions[key].val.toLowerCase()) !== -1;
      });
    },
  }
}
</script>

<style>
.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 11px !important;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 16px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}
</style>
